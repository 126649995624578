.form {
    font-size: 14px;

    label{
        position: relative;
        display: inline-block;
        font-size: 14px;
        text-align: left;
        margin-bottom: 8px;
    }
    label.required::after{
        content: '*';
        position: absolute;
        top: 0;
        right: -8px;
    }
    select,
    input[type=text],
    input[type=email],
    input[type=password],
    input[type=number] {
        width: 100%;
        padding: 10px;
        margin-bottom: 16px;
        border-radius: 4px;
        font-size: 14px;
        text-align: left;
        background: #FFFFFF;
        border: 1px solid #000000;
    }
    input[type="checkbox"]{
        margin-right: 5px;
        margin-bottom: 5px;
        border: 1px solid #FFFFFF;
    }

    textarea{
        padding: 8px;
        overflow: auto;
        display: block;
        width: 100%;
        min-height: 200px;
        background: #FFF;
        border-radius: 4px;
        color:#000000;
    }

    select {
        background-image:
                linear-gradient(45deg, transparent 50%, gray 50%),
                linear-gradient(135deg, gray 50%, transparent 50%),
                linear-gradient(to right, #ccc, #ccc);
        background-position:
                calc(100% - 20px) calc(1em + 2px),
                calc(100% - 15px) calc(1em + 2px),
                calc(100% - 2.5em) 0.5em;
        background-size:
                5px 5px,
                5px 5px,
                1px 1.5em;
        background-repeat: no-repeat;
    }

    select:focus {
        background-image:
                linear-gradient(45deg, gray 50%, transparent 50%),
                linear-gradient(135deg, transparent 50%, gray 50%),
                linear-gradient(to right, #ccc, #ccc);
        background-position:
                calc(100% - 15px) 1em,
                calc(100% - 20px) 1em,
                calc(100% - 2.5em) 0.5em;
        background-size:
                5px 5px,
                5px 5px,
                1px 1.5em;
        background-repeat: no-repeat;
        outline: 0;
    }

    select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }

    button:disabled,
    button[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }

    button{
        width: 100%;
        padding: 8px 15px;
        margin: 20px 0 25px;
        background-color: #000000;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        color: #FFFFFF;
        border: 1px solid #000000;
        transition: background-color .5s ease-out;

        &:hover{
            background-color: #FFFFFF;
            color: #000000;
        }
    }

    &__group{
        position: relative;
        margin-bottom: 15px;
        padding: 5px 0;
        text-align: left;

        &__relative {
            position:relative;
        }

        &__display-password {
            position: absolute;
            right: 11px;
            top: 35px;

            image { opacity: .6 }
        }

        &__info {
            margin-top: 8px;
        }

        &.textarea{
            padding-bottom: 23px;
        }

        &.checkbox{
            padding-bottom: 13px;
        }
    }

    .form__error{
        position: absolute;
        left: 8px;
        bottom: 0;
        color: #FF4653;
        font-size: 12px;
    }
}