body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
}

.container {
  max-width: calc(100% - 1em);
}

.clear { clear: both; }
.text-upper { text-transform: uppercase; }

select {
  min-width: 160px;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;

  background-image:
          linear-gradient(45deg, transparent 50%, gray 50%),
          linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
          calc(100% - 20px) 1em,
          calc(100% - 15px) 1em;
  background-size:
          5px 5px,
          5px 5px;
  background-repeat: no-repeat;
  border: 1px solid #000000;

  &:focus {
    background-image:
            linear-gradient(45deg, gray 50%, transparent 50%),
            linear-gradient(135deg, transparent 50%, gray 50%);
    background-position:
            calc(100% - 15px) 1em,
            calc(100% - 20px) 1em;
    background-size:
            5px 5px,
            5px 5px;
    background-repeat: no-repeat;
    outline: 0;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}
