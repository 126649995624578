@import "../../styles/media-query";

.modal-component {
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;

    .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2200;
        transition: opacity .3s ease;
    }

    .modal {
        width: 50%;
        max-width: 500px;
        background-color: #000000;
        border: 1px solid #FFFFFF;

        @include mobile {
            width: 90%;
        }

        &__header {
            padding: 10px 22px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__title {
                color: #FFFFFF;
                text-align: left;
                font-size: 14px;
                text-transform: uppercase;
            }

            &__close {
                color: #FFFFFF;
                font-size: 1.4em;
                margin-right: -10px;
            }
        }

        &__body {
            padding: 30px;
            color: #FFFFFF;
            font-size: 14px;

            form {
                color: #000000;

                label { color: #FFFFFF; }

                .form-group { margin-bottom: 0; }
            }
        }

        &__footer {
            display: flex;
            justify-content: space-between;
            padding: 22px;

            .button {
                display: inline-block;
                padding: 8px 32px;
                font-size: 12px;
                background-color: #000000;
                text-transform: uppercase;
                color: #FFFFFF;
                border: 1px solid #FFFFFF;
                transition: background-color .5s ease-out;

                &:hover {
                    background-color: #FFFFFF;
                    color: #000000;
                }
            }
        }
    }
}