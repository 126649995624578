$sm: 43em;
$md: 64em;
$lg: 75em;

@mixin mobile {
    @media only screen and (max-width: $sm) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: $md) {
        @content;
    }
    //@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    //    @content;
    //}
}

@mixin desktop {
    @media (min-width: #{$lg}) {
        @content;
    }
}